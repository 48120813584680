import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '@app/shared/constants/constant';
import { CommonService } from '@app/shared/services/common.service';
import { CrewPaxUriConfig } from '../setup-url-constant';

@Injectable({
  providedIn: 'root'
})
export class CrewpaxService {
  private httpRequestType = ApiTypes;
  constructor(private serverConnectService: ServerConnectService, private commonService: CommonService) { }

  public getclientCrew() {
    const databody = '';
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          CrewPaxUriConfig.getCrewSettings,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.GetCrewSettings);
        });
    });
  }


  public deleteuserdata(id, type) {
    const databody = '&id=' + id + '&type=' + type;
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          CrewPaxUriConfig.deletePassenger,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.ResponseStatus);
        });
    });
  }
  public getclientPassenger() {
    const databody = '';
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          CrewPaxUriConfig.getPaxSettings,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.GetPaxSettings);
        });
    });
  }

  // public validName(input) {
  //   const inputregExp = /^[a-zA-Z\s\-_@]+$/;
  //   return inputregExp.test(input);
  // }
  public validEmail(email) {
    const emailRegx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/i
    return emailRegx.test(email);
  }
  public savePassenger(passname, contactno, PassengerDialCode, emailid, dob, nationality, passportno, registereddate, expirydate, passengerid, ImageURL) {
    const databody = '&ClientPassengerName=' + passname +
      '&ClientPassengerContactNo=' + contactno + '&PassengerDialCode=' + encodeURIComponent(PassengerDialCode) + '&ClientPassengerEmailID=' + emailid + '&ClientPassengerDob=' + dob +
      '&ClientPassengerNationality=' + nationality + '&ClientPassengerPassport=' + passportno + '&PassportRegistered=' + registereddate +
      '&PassportExpiry=' + expirydate + '&ClientPassengerID=' + passengerid.value + '&ClientPassengerImageURL=' + encodeURIComponent(ImageURL);
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          CrewPaxUriConfig.savePassengerDetails,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.ResponseStatus);
        });
    });
  }
  public addPassenger(passname, contactno, PassengerDialCode, emailid, dob, nationality, passportno, registereddate, expirydate, ImageURL) {
    const databody = '&ClientPassengerName=' + passname +
      '&ClientPassengerContactNo=' + contactno + '&PassengerDialCode=' + encodeURIComponent(PassengerDialCode) + '&ClientPassengerEmailID=' + emailid + '&ClientPassengerDob=' + dob +
      '&ClientPassengerNationality=' + nationality + '&ClientPassengerPassport=' + passportno + '&PassportRegistered=' + registereddate +
      '&PassportExpiry=' + expirydate + '&ClientPassengerImageURL=' + encodeURIComponent(ImageURL);

    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          CrewPaxUriConfig.savePassengerDetails,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.ResponseStatus);
        });
    });
  }


  public savecrew(crewname, contactno, callingCodes, emailid, dob, nationality, passportno, registereddate, expirydate, duties, crewid, ImageURL) {
    const databody = '&ClientCrewName=' + crewname +
      '&ClientCrewContactNo=' + contactno + '&callingCodes=' + encodeURIComponent(callingCodes) + '&ClientCrewEmailID=' + emailid + '&ClientCrewDob=' + dob +
      '&ClientCrewNationality=' + nationality + '&ClientCrewPassport=' + passportno + '&PassportRegistered=' + registereddate +
      '&PassportExpiry=' + expirydate + '&duties=' + duties + '&ClientCrewID=' + crewid.value + '&ClientCrewImageURL=' + encodeURIComponent(ImageURL);
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          CrewPaxUriConfig.saveCrewDetails,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.ResponseStatus);
        });
    });
  }
  public addCrew(crewname, contactno, callingCodes, emailid, dob, nationality, passportno, registereddate, expirydate, duties, ImageURL) {
    const databody = '&ClientCrewName=' + crewname +
      '&ClientCrewContactNo=' + contactno + '&callingCodes=' + encodeURIComponent(callingCodes) + '&ClientCrewEmailID=' + emailid + '&ClientCrewDob=' + dob +
      '&ClientCrewNationality=' + nationality + '&ClientCrewPassport=' + passportno + '&PassportRegistered=' + registereddate +
      '&PassportExpiry=' + expirydate + '&duties=' + duties + '&ClientCrewImageURL=' + encodeURIComponent(ImageURL);
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          CrewPaxUriConfig.saveCrewDetails,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.ResponseStatus);
        });
    });
  }
}