import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fight-plan-info',
  templateUrl: './fight-plan-info.page.html',
  styleUrls: ['./fight-plan-info.page.scss'],
})
export class FightPlanInfoPage implements OnInit {

  constructor() { }

  public ngOnInit() {
  }

}
