
export class ScheduleInfo {
    public leglist = new Array<TripInfoLegs>();
    public clientUID: string;
    public tripStatus: string;
    public vendorArr: any;
    public quotesstaus: string;
    public CreatedDate: string;
    public Regd: string;
    public Actailnum: string;
    public Actype: string;
}
export class TripInfoLegs {
    public BLKTIME: string;
    public ETDGMT: string;
    public ETAGMT: string;
    public ETALT: string;
    public ETDLT: string;
    public departDate: string;
    public Arrivaltime: any;
    public depart: string;
    public aircraft: number;
    public timezone: boolean;
    public stoptype: any;
    public GMTPLUSORIGIN: string;
    public GMTPLUSDEST: string;
    public TIMEZONE: boolean;
    public routeID: number;
    public timeZone: string;
    public CreatedDate: string;
    public sectorfromtimezone: string;
    public sectortotimezone: string;
    public sectorseltimezone: string;
    public savedlegid: string;
    public from: string;
    public to: string;
    public legid: any;
    public sector: string;
    public fromICAO: string;
    public toICAO: string;
    public flyingSeconds: string;
    public Legstage: string;
    public countdownTime: any;
    public progressDate: string;
    public showStatus: string;
    public progress?: number;
    public DIST?: number;
    public TOTBLKTIME: number;
    public BLKTIMEHRS: number;
    public noOfPax:any;
}

// For charter Invoice Model

export class CharterInvoiceInfo {
    public leglist = new Array<InvoiceLegsInfo>();
    public invoicedata = new InvoiceData();
    public operatordata = new OperatorData();
    public Actailnum: string;
    public actype: string;
}

export class InvoiceLegsInfo {
    public fromICAO: string;
    public toICAO: string;
    public from: string;
    public travelDistrance: string;
    public BLKTIME: string;
    public to: string;
    public departDate: string;
    public Arrivaltime: any;
    public sectorfromtimezone: string;
    public Legstage: string;
    public sectortotimezone: string;
}

export class InvoiceData {
    public Blocktime: string;
    public CIID: string;
    public CharterPrice: number;
    public CharterUserID: string;
    public ClientID: string;
    public DueDate: string;
    public FuelTotal: string;
    public InvDate: string;
    public InvStatus: string;
    public InvoiceTotal: string;
    public SRID: string;
    public TaxPrice: number;
    public Taxprice: number;
}

export class OperatorData {
    public CompanyName: string;
    public ClientOperationContactEmailID: string;
    public ClientOperationContactNo: string;
    public CreatedDate: string;
    public ClientAccDeptContactEmailID: string;
    public ClientAccDeptContactNo: string;

}