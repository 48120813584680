import { trigger, state, style, transition, animate } from '@angular/animations';

export const Animations = {
    flyinAnimation: trigger('flyInOut', [
        state('in', style({ transform: 'translate3d(0, 0, 0)' })),
        transition('void => *', [
            style({ transform: 'translate3d(150%, 0, 0)' }),
            animate(200)
        ]),
        transition('* => void', [
            animate(200, style({ transform: 'translate3d(150%, 0, 0)' }))
        ])
    ]),
    quickQuote: trigger('flyInOut', [
        state(
            'in',
            style({
                transform: 'translate3d(0, 0, 0)',
                overflowY: 'auto',
                position: 'absolute',
                zIndex: '60',
                top: '0',
                right: '0',
                bottom: '0',
                left: '0',
                background: '#ffffff',
            })
        ),
        state(
            'out',
            style({
                transform: 'translate3d(150%, 0, 0)',
                position: 'absolute',
                overflowY: 'hidden',
                height: '0',
            })
        ),
        transition('in => out', animate('200ms ease-in')),
        transition('out => in', animate('200ms ease-out')),
    ]),
}