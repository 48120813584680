import { Leg } from "@app/requests/request-constant";

export class TripModel {
   public badgeShow: boolean = false;
   public vendorLogin: boolean = false;
   public operatorLogin: boolean = false;
   public show: boolean = false;
   public allowToScrollData: boolean = false;
   public statusList: any;
   public typeOfList: string = "All";
   public filterStatus: string = "";
   public searchItemEv:any;
   public roleID:any;
}
export interface VendorTripItemm {
   SRID: string;
   Status: string;
   DT_RowId: string;
   ClientName: string;
   From: string;
   To: string;
   Station: string;
   AircraftType: string;
   depart: Date;
   sectortotimezone: string;
   sectorseltimezone: string;
   sectorfromtimezone: string;
   CreatedDate: Date;
   Service: string;
   ClientFirebase_uid: string;
   clientFirebaseSRID: string;
   Legs: Leg[];
   QuotesStatus: string;
}