export enum InvoiceStatus {
  Pending = 'pending',
  Invoiced = 'invoiced',
  Resolved = 'resolved',
  Dispute = 'disputed',
  InvoiceVendorStatus = 'Invoiced',
  DisputedVendorStatus = 'disputed',
}
export const InvoicesStatusList = [
  {
    icon: 'infinite-outline',
    status: 'All',
    loadStatus: 'tripall',
    statusMethod: 'reloadAllInvoiceList',
  },
  // {
  //   icon: 'contrast-outline',
  //   status: 'Invoiced',
  //   loadStatus: 'pending',
  //   statusMethod: 'loadStatusWiseDatainInvoice',
  // },
  {
    icon: 'contrast-outline',
    status: 'Pending',
    loadStatus: 'pending',
    statusMethod: 'loadStatusWiseDatainInvoice',
  },
  {
    icon: 'thumbs-down-outline',
    status: 'Disputed',
    loadStatus: 'disputed',
    statusMethod: 'loadStatusWiseDatainInvoice',
  },
  {
    icon: 'thumbs-up-outline',
    status: 'Resolved',
    loadStatus: 'resolved',
    statusMethod: 'loadStatusWiseDatainInvoice',
  },
  {
    icon: 'play-forward-outline',
    status: 'Paid',
    loadStatus: 'invoiced',
    statusMethod: 'loadStatusWiseDatainInvoice',
  },
];

export enum ServiceNames {
  FUEL = 'Fuel',
  GROUNDHANDLING = 'Handling',
  AIRPORTCHARGES = 'Apt Fees & Permits',
  NAVIGATION = 'Nav Fees & Permits',
  CREWBRIEFING = 'Flight Planning',
  PERMITS = 'OVF Permits',
}
