import { CommonService } from '@app/shared/services/common.service';
import { BehaviorSubject } from 'rxjs';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { queryParams } from './../../shared/utils/query-params';
import { ServerConnectService } from './../../core/services/server-connect.service';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { InvoiceApiConfig } from '../invoice-url-constant';
import { MessageConfig, ApiTypes } from './../../shared/constants/constant';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  public httpRequestType = ApiTypes;
  public saveUserSessionSubject: any = new BehaviorSubject<any>([]);
  constructor(
    private alertCtrl: AlertController,
    private serverConnectService: ServerConnectService,
    private authSessionService: AuthSessionService,
    private commonService: CommonService
  ) {
  }

  public loadInvoicesList(lastIndexOfInvoice?: string | number) {
    const databody = queryParams({
      lastminsrid: lastIndexOfInvoice,
    });
    return new Promise((resolve, reject) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          this.commonService.roleId == '2'
            ? InvoiceApiConfig.getClientInvoicesAll
            : InvoiceApiConfig.getInvoiceAllVendor,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          const invoiceData =
            this.commonService.roleId == '2'
              ? res.GetClientInvoicesAll
              : res.GetInvoiceAllVendor;
          resolve(invoiceData);
        }).catch((error) => {
          reject(false);
        })
    });
  }
  public async ServiceIssueAlert() {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: MessageConfig.ApiErrorHeader,
        subHeader: MessageConfig.ApiErrorMessage,
        buttons: [
          {
            text: MessageConfig.CancelButton,
            role: 'cancel',
            handler: () => {
              resolve(false);
            },
          },
          {
            text: MessageConfig.TryAgain,
            role: 'ok',
            handler: () => {
              resolve(true);
            },
          },
        ],
      });
      await alert.present();
    });
  }
  public loadInvoiceTripService(InvoiceID) {
    const databody = queryParams({
      SRID: InvoiceID,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          InvoiceApiConfig.getTripServiceInvoice,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.GetTripServiceInvoice);
        });
    });
  }
  public loadInvoiceDetailsByInvoiceID(invoiceID, roleType) {
    const databody = queryParams({
      invoiceid: invoiceID,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          roleType == 'User' ? '' : InvoiceApiConfig.getInvoiceDetails,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.GetInvoiceDetails);
        });
    });
  }
  public uploadSupportingInvoice(InvoiceArr) {
    const databody = queryParams({
      RequestArray: JSON.stringify(InvoiceArr),
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          InvoiceApiConfig.uploadSupportingDocuments,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.UploadSupportingDocuments);
        });
    });
  }
  public saveAdditionalInvoice(invoiceID, invoiceArr) {
    const databody = queryParams({
      invoiceID,
      RequestArray: JSON.stringify(invoiceArr),
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          InvoiceApiConfig.vendorManualInvoiceGenerates,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.VendorManualInvoiceGenerates);
        });
    });
  }
  public downloadInvoice(invoiceId, type) {
    const databody = queryParams({
      invoiceid: invoiceId,
      type,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          InvoiceApiConfig.getInvoiceDownload,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.GetInvoiceDownload);
        });
    });
  }
  public raiseInvoiceIssue(invoiceID, status, type) {
    const databody = queryParams({
      UserEmailID: '',
      UserPass: '',
      enct: 1,
      invoiceID,
      status,
      type,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          InvoiceApiConfig.disputeStatus,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.DisputeStatus);
        });
    });
  }
}
