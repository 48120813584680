export const InvoiceApiConfig = {
  getInvoiceAllVendor: '/index.php?page=API&action=GetInvoiceAllVendor',
  getTripServiceInvoice: '/index.php?page=API&action=GetTripServiceInvoice',
  getInvoiceDetails: '/index.php?page=API&action=GetInvoiceDetails',
  getClientInvoicesAll: '/index.php?page=API&action=GetClientInvoicesAll',
  vendorManualInvoiceGenerates:
    '/index.php?page=API&action=VendorManualInvoiceGenerates',
  uploadSupportingDocuments:
    '/index.php?page=API&action=UploadSupportingDocuments',
  getInvoiceDownload: '/index.php?page=API&action=GetInvoiceDownload',
  disputeStatus: '/index.php?page=API&action=DisputeStatus',
};
