import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '../constants/constant';
import { ApiConfig } from '../constants/url-constant';
import { GetPriceBreakupService } from './get-price-breakup.service';

@Injectable({
  providedIn: 'root',
})
export class TripDetailsService {
  public apiType = ApiTypes;
  constructor(
    private serverConnectService: ServerConnectService,
    private ratebreakupservice: GetPriceBreakupService
  ) { }
  public getPricebreakup(
    SRSecID: any,
    SRID: any,
    Sector: any,
    location: any,
    vendorID: any,
    serviceName: any
  ) {
    let backendServicename: string;
    switch (serviceName) {
      case 'Flight Planning':
        backendServicename = 'crew briefing';
        break;
      case 'Overflight Permits':
        backendServicename = 'permits';
        break;
      case 'Fuel':
        backendServicename = 'fuel';
        break;
      case 'Dep Handling':
      case 'Arr Handling':
      case 'Handling':
        backendServicename = 'groundhandling';
        break;
      case 'Dep Apt Fees & Permits':
      case 'Arr Apt Fees & Permits':
      case 'Apt Fees & Permits':
        backendServicename = 'airportcharges';
        break;
    }
    return new Promise((resolve) => {
      let databody: any;
      databody =
        'SRSecID=' +
        SRSecID +
        '&SRID=' +
        SRID +
        '&Sector=' +
        Sector +
        '&location=' +
        location +
        '&vendorID=' +
        vendorID +
        '&serviceName=' +
        backendServicename;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.GetVendorRateBreakup,
          databody,
          false,
          false,
          false
        )
        .then(
          (data: any) => {
            let frntendServiceName: string;
            switch (serviceName) {
              case 'Flight Planning':
                frntendServiceName = 'Crew Briefing';
                break;
              case 'Overflight Permits':
                frntendServiceName = 'Overflying Permits';
                break;
              case 'Fuel':
                frntendServiceName = 'Fuel';
                break;
              case 'Dep Handling':
              case 'Arr Handling':
              case 'Handling':
                frntendServiceName = 'Ground Handling';
                break;
              case 'Dep Apt Fees & Permits':
              case 'Arr Apt Fees & Permits':
              case 'Apt Fees & Permits':
                frntendServiceName = 'AirPermissions';
                break;
            }
            resolve(
              this.ratebreakupservice.priceFormat(
                frntendServiceName,
                data.GetVendorRateBreakup.vendorinformation
              )
            );
          },
          (err) => {
            resolve('Error');
          }
        );
    });
  }
}
