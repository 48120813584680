import { SharedDirectiveModule } from './../../shared/directives/shared-directive.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RoutePlanPageRoutingModule } from './route-plan-routing.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, RoutePlanPageRoutingModule,SharedDirectiveModule],
  declarations: [],
  exports: [],
})
export class RoutePlanPageModule {}
