import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailRestriction'
})
export class EmailRestrictionPipe implements PipeTransform {
  private emailvalue: any;
  public transform(value: any, charCode: number) {
    const emailvalue = value.replace('..', '.').replace('.@', '@').replace('--', '-').replace(/[^a-zA-Z0-9.\-\@_]/g, '');
    if (emailvalue.includes('@') == true && charCode == 64 && (emailvalue.split("@").length - 1) > 1) {
      const speclcharindex = emailvalue.indexOf('@');
      this.emailvalue = emailvalue.substring(0, speclcharindex) + '' + emailvalue.substring(speclcharindex + 1);
    }
    const email = emailvalue.charAt(0) == "." || (emailvalue.charAt(0) <= '9' && emailvalue.charAt(0) >= '0') ? this.emailvalue = emailvalue.slice(1) : '';
    return emailvalue;
  }
}
