import { ApiConfig } from '@app/shared/constants/url-constant';
import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '@app/shared/constants/constant';

@Injectable({
  providedIn: 'root',
})
export class AddTripdataService {
  public apiType = ApiTypes;
  private passangerdata: any;
  private crewdata: any;
  constructor(private serverConnectService: ServerConnectService) { }
  public showPassanger(srid, sector, legId) {
    return new Promise((resolve) => {
      const databody =
        'enct=1&srid=' + srid + '&sector=' + sector + '&SRSecID=' + legId;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.choosePassengerClient,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          this.passangerdata = data.ChoosePassengerClient.aaData;
          resolve(this.passangerdata);
        })
        .catch((err) => {
          resolve('Error');
        });
    });
  }
  public addpassanger(sector, addpassvalue, srid, legid) {
    return new Promise((resolve) => {
      const databody =
        'sector=' +
        sector +
        addpassvalue +
        '&SRID=' +
        srid +
        '&legid=' +
        legid
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.AddPassengerClient,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.ResponseStatus);
        })
        .catch((err) => {
          resolve('Error');
        });
    });
  }
  public showCrew(srid: string, sector: string, legId: string | number) {
    return new Promise((resolve) => {
      const databody =
        'enct=1&srid=' + srid + '&sector=' + sector + '&SRSecID=' + legId;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.ChooseCrewClient,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          this.crewdata = data.ChooseCrewClient.aaData;
          resolve(this.crewdata);
        })
        .catch((error) => {
          resolve('Error');
        });
    });
  }
  public addcrew(
    sector: string,
    addcrewvalue: string,
    srid: string,
    legid: string | number,
    pageFrom: string
  ) {
    return new Promise((resolve) => {
      const databody =
        'sector=' +
        sector +
        addcrewvalue +
        '&SRID=' +
        srid +
        '&legid=' +
        legid +
        '&pageFrom=' +
        pageFrom;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.AddCrewClient,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.ResponseStatus);
        })
        .catch((error) => {
          resolve('Error');
        });
    });
  }

  public getFlitghtTime(srid, legId) {
    return new Promise((resolve) => {
      const databody =
        'enct=1&SRID=' + srid + '&SRSecID=' + legId;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.getActionItemInfo,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.GetActionItemInfo);
        })
        .catch((err) => {
          resolve('Error');
        });
    });
  }

  public saveFlitghtTime(srid, legId, updateFlightTime) {
    return new Promise((resolve) => {
      const databody =
        'enct=1&SRID=' + srid + '&SRSecID=' + legId + '&chocksoff=' + updateFlightTime.chocksoff + '&chockson=' + updateFlightTime.chockson + '&landed=' + updateFlightTime.landed + '&takeoff=' + updateFlightTime.takeoff + '&BLKTIME=' + updateFlightTime.BLKTIME + '&estflighttime=' + updateFlightTime.estflighttime + '&FuelLeft=' + updateFlightTime.FuelLeft +'&FuelUnitType=' + updateFlightTime.FuelUnitType;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.saveActionItemInfo,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data);
        })
        .catch((err) => {
          resolve('Error');
        });
    });
  }
}
